const injectMail = () => {
  const mail = document.querySelector('#code')
  mail!.innerHTML = atob('aUBpOGUubmV0')
}

const createProgress = () => {
  const progress = document.createElement('div')
  progress.className = 'progress'
  document.body.appendChild(progress)
  return progress
}

const imageToCanvas = (image: HTMLImageElement) => {
  const canvas =
    typeof window.OffscreenCanvas === 'function'
      ? new OffscreenCanvas(image.width, image.height)
      : document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx!.drawImage(image, 0, 0, image.width, image.height)
  return canvas
}

const isOffscreenCanvas = (o: any): o is OffscreenCanvas =>
  typeof window.OffscreenCanvas === 'function' &&
  o instanceof window.OffscreenCanvas

const canvasToBlob = async (canvas: HTMLCanvasElement | OffscreenCanvas) => {
  const blob = isOffscreenCanvas(canvas)
    ? await canvas.convertToBlob()
    : await new Promise<Blob | null>(resolve => {
        canvas.toBlob(resolve)
      })
  return blob
}

const preFetchQueue: string[] = []

const fetchRandomImage = async () => {
  const url =
    window.innerHeight < window.innerWidth
      ? `https://picsum.photos/1080/600/?random&t=${Date.now()}`
      : `https://picsum.photos/600/1080/?random&t=${Date.now()}`

  const image = new Image()
  image.crossOrigin = 'anonymous'
  image.src = url

  await new Promise<void>((resolve, reject) => {
    image.addEventListener('load', () => {
      resolve()
    })
    image.addEventListener('error', e => {
      reject(e.error)
    })
  })
  const canvas = imageToCanvas(image)
  const blob = await canvasToBlob(canvas)
  return URL.createObjectURL(blob!)
}

const preFetchImage = () =>
  fetchRandomImage().then(url => preFetchQueue.push(url))

const getNextImage = () =>
  preFetchQueue.length ? preFetchQueue.shift() : fetchRandomImage()

const injectBackground = () => {
  const bg = document.createElement('div')
  bg.className = 'bg'
  document.body.appendChild(bg)
  return bg
}

const createBgIndicator = () => {
  const container = document.createElement('div')
  container.className = 'progress-indicator-container'
  document.body.appendChild(container)
  const indicator1 = document.createElement('div')
  indicator1.className = 'progress-indicator anim-1'
  container.appendChild(indicator1)
  const indicator2 = document.createElement('div')
  indicator2.className = 'progress-indicator anim-2'
  container.appendChild(indicator2)
  const indicator = document.createElement('div')
  indicator.className = 'progress-indicator'
  container.appendChild(indicator)
  let removed = false
  return {
    indicator,
    removeIndeterminate() {
      if (removed) return
      removed = true
      container.removeChild(indicator1)
      container.removeChild(indicator2)
    },
  }
}

const { indicator, removeIndeterminate } = createBgIndicator()

const bgList: HTMLDivElement[] = []

const removeOldBg = (bg: HTMLDivElement) => {
  if (bgList.length < 3) return
  const item = bgList[bgList.length - 2]
  bg.removeChild(item)
}

let progress = 0

const updateBgIndicator = () => {
  requestAnimationFrame(() => {
    indicator.style.width = ((progress * 100) << 0) + '%'
    updateBgIndicator()
  })
}

const changeBackground = async (bg: HTMLDivElement) => {
  const url = await getNextImage()
  const item = document.createElement('div')
  item.style.backgroundImage = 'url(' + url + ')'
  item.className = 'cur'
  bg.appendChild(item)
  bgList.push(item)
  removeOldBg(bg)
}

let progressUpdateInterval: number

const loopIntervalMS = 10e3

const setupProgressIndicator = () => {
  if (progressUpdateInterval) clearInterval(progressUpdateInterval)
  progress = 0

  progressUpdateInterval = setInterval(() => {
    if (progress < 1) {
      progress += 0.01
    }
  }, loopIntervalMS / 100)
}

const dynamicBackground = () => {
  updateBgIndicator()
  const bg = injectBackground()

  if (~location.hash.indexOf('pure')) return

  let timeout: number
  const loop = async () => {
    timeout = 0

    console.log('change background')
    await changeBackground(bg)
    removeIndeterminate()
    preFetchImage()
    setupProgressIndicator()

    if (timeout) return

    timeout = setTimeout(loop, loopIntervalMS)
  }
  const pauseLoop = () => (timeout && clearTimeout(timeout)) || (timeout = 0)
  const handleHidden = () => {
    console.log('hidden', document.hidden)
    document.hidden ? pauseLoop() : loop()
  }
  document.addEventListener('visibilitychange', handleHidden)

  loop()
}

const init = () => {
  injectMail()
  dynamicBackground()
}

init()
